import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

window.bootstrap = bootstrap;

// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
//   }

//   export const onClientEntry = () => {
//     window.onload = () => {
//       addScript("https://c.o-s.io/258606/tracker.js")
//     }
//   }